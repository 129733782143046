.sidebar {
    min-height: calc(100vh - 55px);
    min-width: 242px;
    background-color: #0A205A;
    display: flex;
    flex-direction: column;
}

.sidebar div {
    padding: 16px;
}

.sidebar p {
    margin: 0;
}

.header-sidebar {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    margin-left: 12px !important;
    cursor: pointer;
}

.menu-list {
    width: calx(100%- 32px);
    margin-top: 8px;
}

.menu-item {
    padding: 8px 14px;
    gap: 12px;
    /* width: 100%; */
    height: 40px;
    /* background: #7A98BE; */
    border-radius: 8px;
    align-items: center;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.dropdown-container {
    /* display: none; */
    /* background-color: #262626; */
    /* padding-left: 8px; */
    margin-top: -30px;
}

.dropdown-container a {
    display: flex;
    padding: 0px 0px 0 30px;
    gap: 12px;
    height: 38px;
    text-decoration: none;
    align-items: center;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.logout-alerts {
    width: 350px;
    /* height: 180px; */
    background-color: white;
    border-radius: 8px;
    -webkit-box-shadow: 3px 3px 10px 0.5px #000000;
    box-shadow: 3px 3px 10px 0.5px #000000;
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text-logout-title {
    font-size: 24px;
}

.logout-gr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px
}
.logout-button {
    font-size: 16px;
    padding: 8px;
    width: 160px;
    color: white;
    cursor: pointer;
}

.bt-cancel {
    background-color: #3A4C7A;
}

.bt-ok {
    background-color: red;
}