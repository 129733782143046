.parkings {
    width: 100%;
    background-color: #F0F1F5;
    padding: 30px;
}

.parkings-container {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: auto;
    position: relative;
}

.parkings-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    margin: 30px;
    color: #040C24;
}

.parkings-table {
    padding: 0 30px 30px 30px;
    max-width: 1400px;
    max-height: 92%;
    /* overflow: auto; */
}

.button-tb {
    width: 135px;
    height: 44px;
    background: #3A4C7A;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}

.parking-setting {
    width: 400px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 3px 3px 10px 0.5px #000000;
    box-shadow: 3px 3px 10px 0.5px #000000;
    padding: 25px;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.parking-setting ::-webkit-scrollbar {
    display: none !important;
}

.parking-setting-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #040C24;
}

.parking-setting-form {
    margin-top: 24px;
}

.input-parking-setting {
    width: 100%;
}

.input-parking-setting label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #040C24;
    margin-bottom: 10px;
}

.input-parking-setting input {
    width: 90%;
    box-sizing: border-box;
    padding: 10px 12px;
    gap: 8px;
    height: 42px;
    /* background: #F2F2F2; */
    border: 1px solid #CDD1D5;
    border-radius: 4px;
    margin-right: 10px;
}

.input-parking-setting input:focus {
    outline: none; /* Loại bỏ outline mặc định */
    border: 2px solid #2684ff; /* Đặt màu viền mà bạn muốn khi input được focus */
}

.parking-setting-buttons {
    margin-top: 40px;
    justify-content: space-between;
}

.parking-setting-button1 {
    background-color: #3A4C7A;
    width: 49%;
    border: none;
    border-radius: 5px;
    height: 45px;
    color: #fff;
    cursor: pointer;
}
.text-14 {
    font-size: 14px;
}

.select-cams {
    width: 90%;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 10px;
}

.err-message {
    color: red !important

}
.switch-close {
    margin-left: 16px;
}