@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Montserrat:wght@100&family=Zen+Kaku+Gothic+Antique:wght@500&display=swap');
.App {
    text-align: center;
}

.main {
    width: 100%;
    /* min-height: 100vh; */
}

.content-body {
    min-height: calc(100vh - 55px);
    width: 100%;
    overflow: auto;
    background-color: #E3E6E8;
}

.content-body-w {
    min-height: 100vh;
    width: 100%;
    overflow: auto;
    background-color: #FFF;
}

.d-flex {
    display: flex !important;
    display: -webkit-flex;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-none {
    display: none !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%
}

.j-center {
    justify-content: center;
}

.al-center {
    align-items: center;
}

.j-sp-ar {
    justify-content: space-around;
}

.j-sp-bw {
    justify-content: space-between;
}
