@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Zen+Kaku+Gothic+Antique:wght@500&display=swap');
.container-login {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #9DA5BD;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 413px;
    height: 407px;
    justify-content: center;
    align-items: flex-start;
    background: #FFFFFF;
    padding: 30px;
    border-radius: 10px;
    -webkit-box-shadow: 3px 3px 10px 0.5px #000000;
    box-shadow: 3px 3px 10px 0.5px #000000;
}

.title {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #1C1E21;
    margin-bottom: 40px;
}

.form {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.form .text-field {
    padding-bottom: 12px;
    width: 100%;
    display: flex;
    flex-direction: column !important;
}

.validate-warning {
    margin: 0;
    padding: 0;
    color: #f70000;
    font-size: 14px;
    font-family: 'Zen Kaku Gothic Antique';
}

.login-input {
    width: 100%;
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    height: 44px;
    /* White */
    background: #FFFFFF;
    border: 1px solid #CDD1D5;
    border-radius: 8px;
    margin-top: 4px;
}

.form div label {
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.form label {
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-top: 8px;
}

.checkbox {
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0 7px 5px 0;
}

.checkbox-text {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #1C1E21;
    margin-top: 8px;
}

.submit-button {
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 150px;
    height: 44px;
    background: #215493;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #215493;
    border-radius: 8px;
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    margin-top: 28px;
}

.submit-button:hover {
    background-color: #33437d;
}