.header {
    width: 100%;
    height: 55px;
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #3A4C7A;
    position: relative;
}

img {
    margin: 20px 12px;
}

.header-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

.bell-icon {
    position: absolute;
    top: 15px;
    color: #FFFFFF;
    bottom: 0;
    right: 50px;
    font-size: 24px;
    cursor: pointer;
}

.error-list {
    position: absolute;
    top: 8px;
    color: #FFFFFF;
    bottom: 0;
    right: 45px;
    background-color: red;
    height: 15px;
    width: 15px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.error-list label {
    font-size: 10px;
    margin-bottom: 2px;
}

.list-alerts {
    position: relative;
    overflow-y: auto;
    width: 300px;
    max-height: 500px;
    background-color: #FFFFFF;
    border-radius: 8px;
    -webkit-box-shadow: 3px 3px 10px 0.5px #000000;
    box-shadow: 3px 3px 10px 0.5px #000000;
    padding: 12px;
    gap: 4px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.list-alerts::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
}

.alerts-item {
    padding: 12px;
    background-color: aliceblue;
}

.alerts-title {
    white-space: nowrap;
    height: auto;
    font-size: 16px;
    font-weight: bold;
    color: red;
}

.alerts-des {
    font-size: 16px;
}

.close-icon {
    position: absolute;
    right: -24px;
    top: -24px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
}

.alerts-empty {
    padding: 48px 0px;
    gap: 24px;
    font-size: 18px;
    color: #b0b0b0;
}

.list-empty {
    font-size: 56px;
    color: #b0b0b0
}
