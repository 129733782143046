.cameras-table {
    padding: 0 30px 30px 30px;
    max-width: 1500px;
    max-height: 92%;
    /* overflow: auto; */
}

.button-tb2 {
    width: 100px;
    height: 44px;
    background: #686D7C;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    line-height: 22px;
}

.reset-request {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.button-tb3 {
    width: 170px;
    height: 44px;
    background: #3A4C7A;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}

.cameras-search {
    max-width: 1000px;
    padding: 0 30px 10px 30px;
    justify-content: space-between;
    align-items: flex-end;
}

.dropdown-search {
    width: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 36px;
    /* font-family: "Inter"; */
    /* background-color: #F2F2F2; */
}

.search-dropdown-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #686D7C;
    margin-bottom: 5px;
}

.dropdown-control-search {
    width: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 36px;
    background: #F2F2F2;
    border: 1px solid #CDD1D5 !important;
    border-radius: 8px !important;
    font-size: 18px;
    /* font-family: "Inter"; */
}

.search-form input {
    width: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 36px;
    padding: 6px 8px;
    border: 1px solid #CDD1D5 !important;
    border-radius: 8px !important;
    font-size: 18px;
    margin-right: 10px;
}

.search-button {
    cursor: pointer;
    background: #3A4C7A;
    width: 100px;
    border-radius: 5px;
    height: 36px;
    color: #FFF;
    font-size: 14px;
}

.mt-2 {
    margin-top: 4px;
}

.mr-5 {
    margin-right: 5px;
}

.bg-delete {
    background-color: #de3535 !important
}

.bg-disable {
    background-color: #c4c4c4 !important;
    color: black
}

.off-cam-modal {
    width: 350px;
    /* height: 0px; */
    background: #FFF;
    border-radius: 4px;
    -webkit-box-shadow: 3px 3px 10px 0.5px #000000;
    box-shadow: 3px 3px 10px 0.5px #000000;
    padding: 36px;
}
.title-off-cam {
    font-size: 24px;
}

.off-cam-buttons-gr {
    margin-top: 20px;
    justify-content: center;
    gap: 16px
}


.off-cam-buttons {
    background-color: #3A4C7A;
    width: 150px;
    border: none;
    border-radius: 5px;
    height: 45px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.button-sim {
    width: 68px !important;;
}

.w-120 {
    width: 100px !important;
}

.gb-cam {
    display: flex;
    flex-wrap: wrap;
    gap: 8px
}

.bt-cancel-sim {
    background-color: #c4c4c4 ;
    color: black;
    font-size: 14px !important;
}

.bt-ok-sim {
    background-color: #3A4C7A;
    font-size: 14px !important;
}