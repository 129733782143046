.dashboard-map * {
    box-sizing: border-box;
  }
  
  html {
    font-size: 10px;
}

.dashboard-cal {
    width: calc(100%);
    background-color: #FFFFFF;
    padding: 50px 0px 250px;
    position: relative;
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
}

.dashboard-container-cal {
    /* width: 100%;
    height: 100%;
    background-color: #FFFFFF; */
    margin-left: auto;
    margin-right: auto;
    padding: 0px 32px 32px;
    width: 1000px;
    max-width: calc(100% - 64px);
    font-weight: 400;
    font-size: 1.6rem;
}
.table-progress-row-cal {
    width: 100%;
    flex-wrap: wrap;
}
.table-progress-row-cal .table-progress-item-cal:nth-child(2n) {
    margin-right: 0;
}
.table-progress-row-cal .table-progress-item-cal:nth-child(n+3) {
    margin-top: 2%;
}

.table-progress-item-cal {
    margin-right: 2%;
    width: calc(49%);
}
.table-progress-title-cal,
.table-progress-content-cal {
    padding: 20px 10px;
    line-height: 1.2;
    font-size: 1.6rem;
    font-weight: 400;
}

.info {
    margin-bottom: 20px;
    text-align: center;
    font-size: 2.6rem;
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
}

.table-progress-title-cal {
    width: 44%;
    background: #ddd;
}

.table-progress-content-cal {
    width: 56%;
    font-weight: 600;
    color: #fff;
}

.progress-text-cal {
    font-family: 'Inter';
    gap: 32px
}

.type-1 {
    background: #60BAD2;
}
.type-2 {
    background: #F7931E;
}
.type-3 {
    background: #875E39;
}
.type-4 {
    background: #CE3636;
}

.logo {
    margin: 0;
    margin-bottom: 45px;
    text-align: center;
}

.logo img {
    width: 325px;
    margin: 0;
}

.copy {
    position: absolute;
    padding: 20px;
    text-align: center;
    font-size: 1rem;
    bottom: 5px;
    left: 0;
    right: 0;
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
    
}

.copy small {
  font-size: 10px;
  /* font-weight: bold; */
}
@media only screen and (max-width: 768px) {
    html {
        font-size: 2vw !important;
    }
    .dashboard-cal {
        font-size: 2rem !important;
        padding-bottom: 100px !important; 
    }

    .logo {
        margin-bottom: 6% !important;
    }
    .logo img {
        width: 70% !important;
    }
    .info {
        margin-bottom: 4% !important;
        font-weight: bold !important;
    }
    .table-progress-row-cal.d-flex {
        display: block !important;
    }
    .table-progress-item-cal {
        margin-right: 0;
        width: calc(100%);
    }
    .table-progress-row-cal .table-progress-item-cal:nth-child(n) {
        margin-top: 3%;
    }
}

@media only screen and (max-width: 1040px) {
    html {
        font-size: 1vw;
    }
    .dashboard-cal {
        padding: 16% 0px 100px !important; 
        min-height: calc(100vh - 64px);
        width: 100%;
    }

    .logo {
        margin-bottom: 4.5%;
    }
    .info {
        margin-bottom: 2%;
    }
}

  .dashboard-map {
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  }
  
  .d-flex {
    display: flex!important;
    display: -webkit-flex;
  }
  
  .flex-row {
    flex-direction: row!important;
  }
  
  .flex-column {
    flex-direction: column!important;
  }
  
  .caption {
    margin-bottom: 65px !important;
    text-align: center;
    em {
        padding: 2px 20px;
        font-weight: 500;
        border-radius: 5px;
        display: inline-block;
        font-style: normal;
        font-size: 1.8rem;
        background: red;
        color: #fff;
    }
  }
  
  .mapping-area {
    margin-bottom: 80px!important;
  }
  
  .mapping-inner {
    position: relative;
  }
  
  
  .mapping-inner img{
    max-width: 100%;
    margin: 0
  }
  
  
  .mapping-pin {
    width: 4.5%;
    position: absolute;
    transition: transform .3s ease;
  }
  
  .mapping-pin:hover {
    transform: scale(1.2);
  }
  
  .mapping-pin.p1 {
    top: calc(630 / 904 * 100%);
    left: calc(260 / 1048 * 100%);
  }
  .mapping-pin.p2 {
    top: calc(292 / 904 * 100%);
    left: calc(677 / 1048 * 100%);
  }
  .mapping-pin.p3 {
    top: calc(412 / 904 * 100%);
    left: calc(866 / 1048 * 100%);
  }
  .mapping-pin.p4 {
    top: calc(668 / 904 * 100%);
    left: calc(550 / 1048 * 100%);
  }
  .mapping-pin.p5 {
    top: calc(168 / 904 * 100%);
    left: calc(630 / 1048 * 100%);
  }
  .mapping-pin.p6 {
    top: calc(499 / 904 * 100%);
    left: calc(37 / 1048 * 100%);
  }
  .mapping-pin.p7 {
    top: calc(42 / 904 * 100%);
    left: calc(656 / 1048 * 100%);
  }
  
  .dashboard-map {
    margin-left: auto;
    margin-right: auto;
    padding: 100px 20px 250px;
    width: 1040px;
    max-width: 100%;
    font-weight: 500;
    font-size: 1.6rem;
    background-color: #FFF;
    position: relative;
  }
  .dashboard-map p {
    margin: 0
  }
  .logo {
    margin: 0;
    margin-bottom: 45px !important;
    text-align: center;
  }
  
  .logo img {
    width: 325px;
  }
  
  .info {
    margin-bottom: 15px !important;
    text-align: center;
    font-size: 2.6rem;
    font-weight: bold;
  }
  
  .time {
    margin-bottom: 20px !important;
    font-size: 20px;
    text-align: center;
  }
  
  .dashboard-container-map {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
  }
  
  .table-progress-map {
  }
  
  .table-progress-row-map {
    flex-wrap: wrap;
    width: 100%;
  }
  
  .table-progress-item-map {
    margin-right: 2%;
    width: 49%;
    border: 1px solid #000;
    font-size: 1.6rem;
  }
  .table-progress-item-map.current {
    outline: 5px solid yellow;
    outline-offset: -3px;
    animation: pin .5s ease-in-out 7 alternate;
  }
  @keyframes pin {
    0% {
        outline: 3px solid rgba(255,255,0,.4);
    }
    100% {
        outline: 3px solid yellow;
    }
  }
  .table-progress-row-map .table-progress-item-map:nth-child(2n) {
    margin-right: 0;
  }
  .table-progress-row-map .table-progress-item-map:nth-child(n+3) {
    margin-top: 2%;
  }
  
  .j-center {
    justify-content: center;
  }
  
  .al-center {
    align-items: center;
  }
  
  .table-progress-title-map,
  .table-progress-content-map {
    padding: 20px 10px;
    line-height: 1.2;
  }
  
  .table-progress-title-map label {
    max-width: 100%;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  
  
  .table-progress-title-map {
    width: 28%;
    font-weight: bold;
  }
  
  .table-progress-content-map {
    width: 60%;
    color: #fff;
    font-weight: bold;
    border-left: 1px solid #000;
  }
  
  .table-progress-icon {
    width: 12%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .5s ease;
    img {
        width: 28%;
    }
  }
  .table-progress-icon:hover {
    opacity: .7;
  }
  .table-progress-content-map.type-1 {
    background: #60BAD2;
  }
  .table-progress-content-map.type-2 {
    background: #F7931E;
  }
  .table-progress-content-map.type-3 {
    background: #875E39;
  }
  .table-progress-content-map.type-4 {
    background: #CE3636;
  }
  .table-progress-content-map.type-5 {
    color: #FF0000;
    outline: 3px solid red;
    outline-offset: -3px;
  }

  .table-progress-content-map.type-6 {
    color: #2E8936;
    outline: 3px solid #2E8936;
    outline-offset: -3px;
  }
  
  .w-full {
    width: 100%;
  }
  
  /* These were inline style tags. Uses id+class to override almost everything */
  
  #style-HY714.style-HY714 {
    font-weight: 600;
  }
  
  #style-zj8e2.style-zj8e2 {
    font-weight: 600;
  }
  
  #style-Cm11P.style-Cm11P {
    font-weight: 600;
  }
  
  #style-d4DeT.style-d4DeT {
    font-weight: 600;
  }
  
  .copy {
    padding: 20px;
    text-align: center;
    font-size: 1rem;
    position: absolute;
  }
  
  @media only screen and (max-width: 1040px) {
  
    html {
        font-size: 1vw;
    }
  
    .dashboard-map {
        padding: 16% 20px 25%;
    }
  
    .logo {
        margin-bottom: 4.5% !important;
    }
  
    .info {
        margin-bottom: 2% !important;
    }
  
  }
  
  @media only screen and (max-width: 768px) {
  
    html {
        font-size: 2vw;
    }
  
    .dashboard-map {
        font-size: 2rem;
    }
  
    .logo {
        margin-bottom: 6% !important;
    }
  
    .logo img {
        width: 70%;
    }
  
    .info {
        margin-bottom: 4% !important;
        font-weight: bold;
    }
  
    .table-progress-row-map.d-flex {
        display: block !important;
    }
  
    .table-progress-item-map {
        margin-right: 0;
        width: 100%;
    }
  
    .table-progress-row-map .table-progress-item-map:nth-child(n+2) {
        margin-top: 3%;
    }
  
    .mapping-area {
        margin-left: -20px;
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% + 40px);
        overflow-x: scroll;
        .mapping-inner {
            max-width: unset;
            width: 170%;
        }
    }
  }
  .note-text {
    margin-top: 52px;
    font-size: 16px;
    font-weight: bold;
  }
  
  