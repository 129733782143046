.dashboard {
    /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
    width: 100%;
    background-color: #F0F1F5;
    padding: 30px;
    /* overflow: hidden; */
}

.dashboard-container {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    /* padding: 30px; */
}

.table-progress {
    margin: 30px;
    border: 1px solid #040C24;
    border-radius: 5px;
}

.table-progress-row {
    width: 100%;
    flex-wrap: wrap;
}

.table-progress-item {
    width: 50%;
}

.progress-text label {
    display: none;
}

.wrapper {
    border: 6px solid #A5AAB4;
    width: 80%;
    /* height: 15px; */
    border-radius: 20px;
}

.table-progress-title {
    padding: 0 4px;
    height: 100px;
    width: 135px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    color: #FFFFFF;
    background-color: #3A4C7A;
    border: 1px solid #040C24;
}

.truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-progress-content {
    height: 100px;
    width: calc(100% - 135px);
    border: 1px solid #040C24;
    gap: 8px
}

.progress-text {
    font-family: 'Inter';
    margin-bottom: 5px;
    gap: 32px;
    font-size: 16px;
}

.progress-info {}

.dashboard-button div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    background-color: #0A205A;
    width: 430px;
    height: 60px;
    margin-top: 28px;
    border-radius: 5px;
    cursor: pointer;
}

.Toastify__toast-body {
    font-size: 16px;
}