.camera-detail-container {
    width: 100%;
    background-color: #F0F1F5;
    padding: 30px;
    height: calc(100vh - 115px);
    overflow-y: scroll;
}

.camera-container {
    height: calc(100%);
    min-height: 800px;
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: auto;
    position: relative;
}

.camera-control {
    width: 100%;
    min-width: 1400px;
    height: 500px;
    padding-top: 20px;
}

.f-16px {
    font-size: 16px;
    color: #686D7C
}

.cam-ip-label {
margin-right: 16px;
}

.al-st {
    justify-content: start;
    align-items: center;
}

.camera-info {
    /* width: 70%; */
    height: 100%;
    margin-left: 30px;
    position: relative;
}

.camera-info img {
    margin: 0 !important;
    object-fit: contain;
}

.camera-control-group {
    width: calc(30%);
    height: 100%;
    justify-content: space-between;
}

.top-button {
    width: 240px;
    height: 50px;
    background-color: #0A205A;
    border-radius: 5px;
    cursor: pointer;
    color: #FFF;
    min-width: 180px;
    margin-left: 30px;
    font-size: 16px;
}

.bottom-button {
    width: 180px;
    height: 54px;
    background-color: #0A205A;
    border-radius: 5px;
    cursor: pointer;
    color: #FFF;
    min-width: 180px;
    /* margin-left: 30px; */
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: 16px;
}

.group-button {
    height: 60%;
    width: 100%;
    justify-content: space-between;
}

.group-button1 {
    height: 50%;
    width: 240px;
    margin-left: 30px;
}

.button-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0 30px 20px 30px;
    white-space: nowrap;
    min-height: 22px;
}

.button-arrow {
    width: 45px;
    height: 45px;
    background-color: #0A205A;
    border-radius: 5px;
    cursor: pointer;
    color: #FFF;
    font-size: 16px;
    /* margin: 5px; */
}

.ar-up-right {
    transform: rotate(40deg);
}

.mg-30 {}

body {
    padding: 0;
    overflow-x: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.overlay.highlighting {
    background: none;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
}

.overlay,
.overlay:before,
.overlay:after {
    box-sizing: border-box;
}

.group-button-cf {
    margin: 20px 0;
}

.modal-cf {
    width: 399px;
    min-height: 100px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    justify-content: center;
    align-items: center;
}

.modal-cf-title {
    width: 100%;
    height: 10%;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #1C1E21;
}

.modal-cf-buttons {
    width: 100%;
    height: 20%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.modal-cf-button2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 49%;
    height: 44px;
    background: #0A205A;
    /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
    border-radius: 8px;
    outline: none;
    border-color: #0A205A;
    color: #FFFFFF;
    outline: none;
    border: none;
    font-size: 14px !important;
}

.err-config {
    color: red;
    margin-left: 26px;
    margin-top: 20px;
}

.modal-cf-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 49%;
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid rgba(33, 84, 147, 0.3);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
    outline: none;
    font-size: 14px !important;
    border-color: #0A205A;
    color: #0A205A;
}